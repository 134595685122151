
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexyazhAZ1Zwl8rkSR_zghAz_hRNJNUALKf1VCv8te3gNMMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/index.vue?macro=true";
import { default as indexqDNa1n5RBq5S9_jJ8cqCG9JGkttoKDewDtMG4mhI2_454Meta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as started_457awuSvIw9GmHXjKD9sWUh_45jee2jnKsYVB6oS0qHsMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startjy1wKg6XvIAnFs3GPOal8GR5ad1N7jvgfRVmWIAAFtAMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutzCCi29sCI9prB1MyM09MpaTx3b6RFB_TyT1mwXiVFZwMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koxl1eaI_457KPEuK4pxDcaxHcb2CnGYgvVQSqbC_ptXbPoMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationniNtxDJxW74YPdEfSzn9ZxcyH6MMl7FMktji2YlboYgMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmation280hgTBEUySvdbQtSDWRgOO_45VLzL8pce_S3S0254nR0Meta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerX8RraZSDLwDtcoikI6dolqDDzh_VEzX72Up7lPIt4MsMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerzpugL3H_5FJjhdiC9HG8VS52tPPeUNRe_KL_45FoSSjJ8Meta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentPRxFlUVwW73a1YWK9o53MU00flBb97dcvVJ03IOljgoMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengedEF1_1Y8py3YuSnzjeoCUiA104wxnfnmIqy_s4uCgLMMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as payment9_45BMHupfb6SNQDH12S1WTRoC23IhjCEjsXaOo8DvEr4Meta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta } from "/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexyazhAZ1Zwl8rkSR_zghAz_hRNJNUALKf1VCv8te3gNMMeta?.name ?? "index",
    path: indexyazhAZ1Zwl8rkSR_zghAz_hRNJNUALKf1VCv8te3gNMMeta?.path ?? "/",
    props: indexyazhAZ1Zwl8rkSR_zghAz_hRNJNUALKf1VCv8te3gNMMeta?.props ?? false,
    meta: indexyazhAZ1Zwl8rkSR_zghAz_hRNJNUALKf1VCv8te3gNMMeta || {},
    alias: indexyazhAZ1Zwl8rkSR_zghAz_hRNJNUALKf1VCv8te3gNMMeta?.alias || [],
    redirect: indexyazhAZ1Zwl8rkSR_zghAz_hRNJNUALKf1VCv8te3gNMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/index.vue")
  },
  {
    name: indexqDNa1n5RBq5S9_jJ8cqCG9JGkttoKDewDtMG4mhI2_454Meta?.name ?? "lang",
    path: indexqDNa1n5RBq5S9_jJ8cqCG9JGkttoKDewDtMG4mhI2_454Meta?.path ?? "/:lang?",
    props: indexqDNa1n5RBq5S9_jJ8cqCG9JGkttoKDewDtMG4mhI2_454Meta?.props ?? false,
    meta: indexqDNa1n5RBq5S9_jJ8cqCG9JGkttoKDewDtMG4mhI2_454Meta || {},
    alias: indexqDNa1n5RBq5S9_jJ8cqCG9JGkttoKDewDtMG4mhI2_454Meta?.alias || [],
    redirect: indexqDNa1n5RBq5S9_jJ8cqCG9JGkttoKDewDtMG4mhI2_454Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: started_457awuSvIw9GmHXjKD9sWUh_45jee2jnKsYVB6oS0qHsMeta?.name ?? "lang-voucher-start",
    path: started_457awuSvIw9GmHXjKD9sWUh_45jee2jnKsYVB6oS0qHsMeta?.path ?? "/:lang?/voucher/start",
    props: started_457awuSvIw9GmHXjKD9sWUh_45jee2jnKsYVB6oS0qHsMeta?.props ?? false,
    meta: started_457awuSvIw9GmHXjKD9sWUh_45jee2jnKsYVB6oS0qHsMeta || {},
    alias: started_457awuSvIw9GmHXjKD9sWUh_45jee2jnKsYVB6oS0qHsMeta?.alias || [],
    redirect: started_457awuSvIw9GmHXjKD9sWUh_45jee2jnKsYVB6oS0qHsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startjy1wKg6XvIAnFs3GPOal8GR5ad1N7jvgfRVmWIAAFtAMeta?.name ?? "lang-purchase-start",
    path: startjy1wKg6XvIAnFs3GPOal8GR5ad1N7jvgfRVmWIAAFtAMeta?.path ?? "/:lang?/purchase/start",
    props: startjy1wKg6XvIAnFs3GPOal8GR5ad1N7jvgfRVmWIAAFtAMeta?.props ?? false,
    meta: startjy1wKg6XvIAnFs3GPOal8GR5ad1N7jvgfRVmWIAAFtAMeta || {},
    alias: startjy1wKg6XvIAnFs3GPOal8GR5ad1N7jvgfRVmWIAAFtAMeta?.alias || [],
    redirect: startjy1wKg6XvIAnFs3GPOal8GR5ad1N7jvgfRVmWIAAFtAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutzCCi29sCI9prB1MyM09MpaTx3b6RFB_TyT1mwXiVFZwMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutzCCi29sCI9prB1MyM09MpaTx3b6RFB_TyT1mwXiVFZwMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutzCCi29sCI9prB1MyM09MpaTx3b6RFB_TyT1mwXiVFZwMeta?.props ?? false,
    meta: checkoutzCCi29sCI9prB1MyM09MpaTx3b6RFB_TyT1mwXiVFZwMeta || {},
    alias: checkoutzCCi29sCI9prB1MyM09MpaTx3b6RFB_TyT1mwXiVFZwMeta?.alias || [],
    redirect: checkoutzCCi29sCI9prB1MyM09MpaTx3b6RFB_TyT1mwXiVFZwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koxl1eaI_457KPEuK4pxDcaxHcb2CnGYgvVQSqbC_ptXbPoMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koxl1eaI_457KPEuK4pxDcaxHcb2CnGYgvVQSqbC_ptXbPoMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koxl1eaI_457KPEuK4pxDcaxHcb2CnGYgvVQSqbC_ptXbPoMeta?.props ?? false,
    meta: koxl1eaI_457KPEuK4pxDcaxHcb2CnGYgvVQSqbC_ptXbPoMeta || {},
    alias: koxl1eaI_457KPEuK4pxDcaxHcb2CnGYgvVQSqbC_ptXbPoMeta?.alias || [],
    redirect: koxl1eaI_457KPEuK4pxDcaxHcb2CnGYgvVQSqbC_ptXbPoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationniNtxDJxW74YPdEfSzn9ZxcyH6MMl7FMktji2YlboYgMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationniNtxDJxW74YPdEfSzn9ZxcyH6MMl7FMktji2YlboYgMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationniNtxDJxW74YPdEfSzn9ZxcyH6MMl7FMktji2YlboYgMeta?.props ?? false,
    meta: confirmationniNtxDJxW74YPdEfSzn9ZxcyH6MMl7FMktji2YlboYgMeta || {},
    alias: confirmationniNtxDJxW74YPdEfSzn9ZxcyH6MMl7FMktji2YlboYgMeta?.alias || [],
    redirect: confirmationniNtxDJxW74YPdEfSzn9ZxcyH6MMl7FMktji2YlboYgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmation280hgTBEUySvdbQtSDWRgOO_45VLzL8pce_S3S0254nR0Meta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmation280hgTBEUySvdbQtSDWRgOO_45VLzL8pce_S3S0254nR0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmation280hgTBEUySvdbQtSDWRgOO_45VLzL8pce_S3S0254nR0Meta?.props ?? false,
    meta: confirmation280hgTBEUySvdbQtSDWRgOO_45VLzL8pce_S3S0254nR0Meta || {},
    alias: confirmation280hgTBEUySvdbQtSDWRgOO_45VLzL8pce_S3S0254nR0Meta?.alias || [],
    redirect: confirmation280hgTBEUySvdbQtSDWRgOO_45VLzL8pce_S3S0254nR0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerX8RraZSDLwDtcoikI6dolqDDzh_VEzX72Up7lPIt4MsMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerX8RraZSDLwDtcoikI6dolqDDzh_VEzX72Up7lPIt4MsMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerX8RraZSDLwDtcoikI6dolqDDzh_VEzX72Up7lPIt4MsMeta?.props ?? false,
    meta: buyerX8RraZSDLwDtcoikI6dolqDDzh_VEzX72Up7lPIt4MsMeta || {},
    alias: buyerX8RraZSDLwDtcoikI6dolqDDzh_VEzX72Up7lPIt4MsMeta?.alias || [],
    redirect: buyerX8RraZSDLwDtcoikI6dolqDDzh_VEzX72Up7lPIt4MsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerzpugL3H_5FJjhdiC9HG8VS52tPPeUNRe_KL_45FoSSjJ8Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerzpugL3H_5FJjhdiC9HG8VS52tPPeUNRe_KL_45FoSSjJ8Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerzpugL3H_5FJjhdiC9HG8VS52tPPeUNRe_KL_45FoSSjJ8Meta?.props ?? false,
    meta: buyerzpugL3H_5FJjhdiC9HG8VS52tPPeUNRe_KL_45FoSSjJ8Meta || {},
    alias: buyerzpugL3H_5FJjhdiC9HG8VS52tPPeUNRe_KL_45FoSSjJ8Meta?.alias || [],
    redirect: buyerzpugL3H_5FJjhdiC9HG8VS52tPPeUNRe_KL_45FoSSjJ8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentPRxFlUVwW73a1YWK9o53MU00flBb97dcvVJ03IOljgoMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentPRxFlUVwW73a1YWK9o53MU00flBb97dcvVJ03IOljgoMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentPRxFlUVwW73a1YWK9o53MU00flBb97dcvVJ03IOljgoMeta?.props ?? false,
    meta: paymentPRxFlUVwW73a1YWK9o53MU00flBb97dcvVJ03IOljgoMeta || {},
    alias: paymentPRxFlUVwW73a1YWK9o53MU00flBb97dcvVJ03IOljgoMeta?.alias || [],
    redirect: paymentPRxFlUVwW73a1YWK9o53MU00flBb97dcvVJ03IOljgoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengedEF1_1Y8py3YuSnzjeoCUiA104wxnfnmIqy_s4uCgLMMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengedEF1_1Y8py3YuSnzjeoCUiA104wxnfnmIqy_s4uCgLMMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengedEF1_1Y8py3YuSnzjeoCUiA104wxnfnmIqy_s4uCgLMMeta?.props ?? false,
    meta: challengedEF1_1Y8py3YuSnzjeoCUiA104wxnfnmIqy_s4uCgLMMeta || {},
    alias: challengedEF1_1Y8py3YuSnzjeoCUiA104wxnfnmIqy_s4uCgLMMeta?.alias || [],
    redirect: challengedEF1_1Y8py3YuSnzjeoCUiA104wxnfnmIqy_s4uCgLMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: payment9_45BMHupfb6SNQDH12S1WTRoC23IhjCEjsXaOo8DvEr4Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: payment9_45BMHupfb6SNQDH12S1WTRoC23IhjCEjsXaOo8DvEr4Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: payment9_45BMHupfb6SNQDH12S1WTRoC23IhjCEjsXaOo8DvEr4Meta?.props ?? false,
    meta: payment9_45BMHupfb6SNQDH12S1WTRoC23IhjCEjsXaOo8DvEr4Meta || {},
    alias: payment9_45BMHupfb6SNQDH12S1WTRoC23IhjCEjsXaOo8DvEr4Meta?.alias || [],
    redirect: payment9_45BMHupfb6SNQDH12S1WTRoC23IhjCEjsXaOo8DvEr4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.path ?? "/pt/spas/",
    props: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.props ?? false,
    meta: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta || {},
    alias: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.alias || [],
    redirect: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.path ?? "/pt/spas/sevilla/",
    props: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.props ?? false,
    meta: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta || {},
    alias: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.alias || [],
    redirect: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.path ?? "/pt/spas/sevilla/sevilla/",
    props: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.props ?? false,
    meta: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta || {},
    alias: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.alias || [],
    redirect: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.path ?? "/pt/spas/sevilla/umbrete/",
    props: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.props ?? false,
    meta: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta || {},
    alias: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.alias || [],
    redirect: SpaList_46pageZFRSazg1L3fpA_45UXvqg_45_RkdpAxzHpJnaw4zy6qXh9YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/",
    props: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.props ?? false,
    meta: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta || {},
    alias: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.alias || [],
    redirect: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/",
    props: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.props ?? false,
    meta: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta || {},
    alias: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.alias || [],
    redirect: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/",
    props: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.props ?? false,
    meta: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta || {},
    alias: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.alias || [],
    redirect: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/",
    props: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.props ?? false,
    meta: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta || {},
    alias: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.alias || [],
    redirect: SpaDetail_46pagetNdR3XaqKZ2Fu7aDowDK2xVmKMLwm3tXiivhBvtcosEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/etna-circuito-massagem-de-aromaterapia/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/massagem-com-velas-quentes/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/circuito-das-termas-de-kumaras/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/terapia-de-chocolate-termoactivo/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/nirvana-circuito-privado-massagem-com-velas/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/kumaras-sensations-privado/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/paani-plus-circuito-massagem-de-relaxamento/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/massagem-com-pedras-vulcanicas/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/massagem-de-relaxamento/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/vinoterapia/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/champi-massagem/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/esfoliante-aromatico-energizante-com-especiarias-raras-e-preciosas/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/beldi-sabonete-preto-esfoliante-purificante/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ko-bi-do-global-anti-aging-precious-treatment/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-de-aromaterapia/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/passe-de-um-dia-de-sexta-feira-a-domingo/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/passe-diario-de-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/relaxar-e-desfrutar/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-oriental-de-relaxamento-50/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-balinesa-wrap-around/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-desportiva/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-indiana-ayurvedica-revigorante/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamento-regenerador-ritual-de-flores-e-frutos-de-bali/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-oriental-relaxante-30/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-as-costas/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamento-de-alisamento-e-firmeza-ko-bi-do/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-sensorial-a-cabeca/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-evasion-spa-massagem/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-wellness-brunch-para-2-pessoas-spa-ritual-brunch/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/beleza-essencial-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/tired-legs-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/circuito-de-spa-privado-para-duas-pessoas/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-relax-spa-massagem-gastronomia/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/massagem-de-gravidez-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/circuito-de-spa-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/alegria-de-azahar-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/massagem-de-tecidos-profundos-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cidade-de-purificacao-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/aromaterapia-relax-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-wellness-para-2-pessoas-spa-ritual/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/eternal-youth-para-uma-pessoa/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/minerva-circuito-massagem-holistica/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/marenostrum-circuito-massagem/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/triton-circuito-flotarium/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/ritual-de-relaxamento/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/flotario-de-30/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/flotarium-15/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/circuito-hispalis/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/ritual-al-andalus/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/eros-circuito-privado-ritual-al-andalus/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/terapia-do-vinho/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/terapia-termoactiva-com-chocolate/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/neptuno-circuito-flotarium-massagem/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/hispalis-sensations-circuito-privado/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/massagem-com-pedras-vulcanicas/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/massagem-de-relaxamento/",
    props: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.props ?? false,
    meta: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta || {},
    alias: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.alias || [],
    redirect: ServiceDetail_46pageDHFdoFMpmFz7roxvQ1RAVRNV2oBI8lzRBSdwMiwJ5eMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-2cd454d1-315c-4524-8e05-cfbf61c99e0c/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]